import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function slow<T>(duration: number): (data: T) => Promise<T> {
  return function (data: T) {
    return new Promise<T>((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, duration);
    });
  };
}

export const isObject = (obj: unknown) =>
  obj !== null && typeof obj === "object" && !Array.isArray(obj);

export const isEmptyObject = (obj: unknown) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;
